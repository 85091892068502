@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');
:root {
  --ant-primary-color: #012169;
  --ant-primary-color-hover: #566373;
  --ant-primary-color-active: #566373;
  --ant-primary-color-outline: #566373;
  --warning-color: #ae6c3d;
  --danger-color: #d30c1d;
  --success-color: #3ca52a;
  --dark: #001529;
  --dark-transparent: #001529cf;
  --white-transparent: #ffffff0a;
}
body {
  font-family: 'Montserrat' !important;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.ant-input,
.ant-select-selector,
.ant-input-number-wrap,
.ant-input-affix-wrapper {
}

.ant-btn {
  height: 40px !important;
  font-weight: bold !important;
  letter-spacing: 1px !important;
}
.form-wrapper {
  padding: 1rem;
}
.checkbox-label {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 10px;
}
@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1340px;
    max-width: 100%;
  }
}
.bg-main {
  background: rgb(1, 33, 105);
  background: linear-gradient(162deg, rgba(1, 33, 105, 1) 0%, rgba(0, 21, 41, 1) 62%);
}
.d-flex {
  display: flex;
}
.d-block {
  display: block;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-space-between {
  justify-content: space-between !important;
}
.rounded {
  border-radius: 1rem !important;
}
.rounded-top-left {
  border-top-left-radius: 1rem !important;
}
.rounded-top-right {
  border-top-right-radius: 1rem !important;
}
.rounded-bottom-left {
  border-bottom-left-radius: 1rem !important;
}
.rounded-bottom-right {
  border-bottom-right-radius: 1rem !important;
}
.ant-modal-content,
.ant-modal-header {
  border-radius: 1rem !important;
}
.p-5 {
  padding: 3rem;
}
.bg-white {
  background-color: #fff;
}
.min-vh-100 {
  height: 100vh;
}
.h-100 {
  height: 100%;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mw-300 {
  max-width: 300px !important;
}
.mw-200 {
  max-width: 200px !important;
}
.mw-150 {
  max-width: 150px !important;
}
.logo {
  max-width: 80% !important;
}
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.my-2 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mb-1 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.ms-1 {
  margin-left: 0.75rem !important;
}
.ms-2 {
  margin-left: 2rem !important;
}
.ml-4 {
  margin-left: 4rem !important;
}
.me-2 {
  margin-right: 2rem !important;
}
.p-2 {
  padding: 2rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.fs-1 {
  font-size: 3rem !important;
}
.fs-2 {
  font-size: 2rem !important;
}
.fs-3 {
  font-size: 1.2rem !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.large-title {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.fw-900 {
  font-weight: 900;
}
.ant-card-meta-title {
  font-weight: bold !important;
}
.ant-modal-title {
  font-weight: bold !important;
  font-size: 18px !important;
}
.ant-btn-primary {
  min-width: 150px !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.5) !important;
}
.big-title {
  font-size: 2rem;
  font-weight: bold;
}
.kat-bg {
  display: block;
  max-height: 200px;
  object-fit: cover;
}
/*REGISTER FROM CSS*/
.kat-vibrate {
  -webkit-animation: vibrate 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  animation: vibrate 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 300px;
  perspective: 300px;
}
@keyframes vibrate {
  0.50%,
  10%,
  20%,
  30%,
  40%,
  50% {
    -webkit-transform: translate3d(1px, 0, 0);
    transform: translate3d(1px, 0, 0);
  }
  5%,
  15%,
  25%,
  35%,
  45% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(1px, 0, 0);
    transform: translate3d(1px, 0, 0);
  }
}
.ant-form-item:last-child {
  margin-bottom: 0 !important;
}
.ant-form-item-label > label {
  text-transform: uppercase;
  font-size: 12px !important;
}
.row-form-item {
  margin-bottom: 24px;
}
.ant-checkbox-inner {
  border: 1px solid #012169 !important;
}
.main-page-title {
  font-weight: bold;
  text-transform: uppercase;
  color: var(--ant-primary-color-active);
}
.heading {
  font-weight: 900;
}
.highlight {
  color: #566373;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-radius: 2rem;
}
.ant-card-head-title {
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 2px;
}
.ant-menu-item {
  width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.ant-layout-sider {
  background: transparent;
}
.ant-btn-icon-only {
  color: #fff !important;
  background-color: var(--ant-primary-color) !important;
  width: 30px !important;
  height: 30px !important;
}
.headerless-table .ant-table-thead > tr > th {
  background: #fff !important;
  font-weight: bold;
  font-size: 12px;
}
.color-warning {
  color: var(--warning-color) !important;
}
.color-success {
  color: var(--success-color) !important;
}
.color-danger {
  color: var(--danger-color) !important;
}
.pb-row {
  border-bottom: 1px solid #c1c1c1;
  margin: 1rem 0;
}
/*DARK STYLE*/
/* .ant-layout-sider {
  background: var(--dark) !important;
}
.ant-layout{
  background-color: var(--dark-transparent) !important;
}
.ant-card{
  background-color: var(--white-transparent) !important;
}
.ant-card-head{
  color: #fff !important;
}
.ant-card-body{
  color: #b5b5b5;
} */

/*END DARK STYLE*/

.ant-table-thead > tr > th {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 10px;
}
.ant-form-item-label > label {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 10px !important;
}
.ant-table-cell > a {
  color: var(--ant-primary-color);
  font-weight: bold;
  text-decoration: underline;
}
.all-steps {
  margin-bottom: 5rem;
}
.plan-card {
  border-radius: 4px !important;
  border: 3px solid #0121691a !important;
}
.active-plan-card {
  border: 3px solid #a1cdea !important;
  -webkit-box-shadow: 0px 10px 16px 8px rgba(227, 227, 227, 1);
  -moz-box-shadow: 0px 10px 16px 8px rgba(227, 227, 227, 1);
  box-shadow: 0px 10px 16px 8px rgba(227, 227, 227, 1);
}
.border-top-stripped {
  border-top: 1px dashed #c1c1c1;
  padding-top: 1rem;
}
.selected-plan-tag {
  position: absolute;
  top: -10px;
  right: -10px;
}
.email-like-button {
  background: #012169;
   text-decoration: none;
padding: 10px 25px;
color: #ffffff;
border-radius: 4px;
display: inline-block;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 768px) {
  .row-form-item > .ant-col:first-child {
    margin-bottom: 24px;
  }
}
